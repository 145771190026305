import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Img from "gatsby-image"

const Apie = ({ data }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  console.log("hey", data)
  return (
    <Layout bodyClass="page-default-single">
      <div className="container pb-6 pt-6 pt-md-10 pb-md-10">
        <div className="row justify-content-start">
          <div className="col-12 col-md-8">
            <h1 className="title">{title}</h1>
            <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
          </div>
          <div className="col-12 col-md-4 certificates">
            <div className="row">
              <div className="col-12">
                <a href="/images/certificates/certificate-1.jpg" target="_blank">
                  <p>Apsaugos licencija</p>
                  <Img fixed={data.cert1.childImageSharp.fixed} alt="Apsaugos licencija" />
                </a>
              </div>
              <div className="col-12">
                <a href="/images/certificates/certificate-2.jpg" target="_blank">
                  <p>Darbų vadovo kvalifikacijos atestatas</p>
                  <Img fixed={data.cert2.childImageSharp.fixed} alt="Darbų vadovo kvalifikacijos atestatas" />
                </a>
              </div>
              <div className="col-12">
                <a href="/images/certificates/certificate-5.png" target="_blank">
                  <p>Ypatingo statinio statybos rangovo kvalifikacijos atestatas</p>
                  <Img fixed={data.cert5.childImageSharp.fixed} alt="Ypatingo statinio statybos rangovo kvalifikacijos atestatas" />
                </a>
              </div>
              <div className="col-12">
                <a href="/images/certificates/certificate-4.jpg" target="_blank">
                  <p>Licencija gaminti priešgaisrinę įrangą ir atlikti jos techninę priežiūrą</p>
                  <Img fixed={data.cert4.childImageSharp.fixed} alt="Licencija gaminti priešgaisrinę įrangą ir atlikti jos techninę priežiūrą" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        path
      }
      fields {
        slug
      }
      html
    }
    cert1: file(relativePath: { eq: "certificate-1.jpg" }) {
      childImageSharp {
        fixed(width: 150, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cert2: file(relativePath: { eq: "certificate-2.jpg" }) {
      childImageSharp {
        fixed(width: 150, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cert5: file(relativePath: { eq: "certificate-5.png" }) {
      childImageSharp {
        fixed(width: 150, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    cert4: file(relativePath: { eq: "certificate-4.jpg" }) {
      childImageSharp {
        fixed(width: 150, height: 175) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default Apie;
